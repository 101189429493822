<template>
  <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 p-0">
    <Summary :theDetails="value" @goToDonorPage="$emit('goToDonorPage')" @goToPaymentPage="$emit('goToPaymentPage')" />
    <div class="row mx-0 justify-content-center" v-if="value.recurringDonationType === 'CREDIT_CARD'">
      <div class="col-10 mt-4 text-center">
        <div class="form-check form-switch position-relative">
          <label class="form-check-label bold" for="flexSwitchCheckDefault">Sponsor bank charges</label>
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="value.transactionDTO.sponsored">
        </div>
      </div>
    </div>
    <div class="row mx-0 justify-content-center">
      <div class="col-10 my-4 text-center divider">
      </div>
    </div>
    <SetReference v-model="value.reference" />
    <AcceptTerms v-model="termsValue" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation',
  components: {
    Summary: defineAsyncComponent(() => import('./views/Summary.vue')),
    SetReference: defineAsyncComponent(() => import('./views/SetReference.vue')),
    AcceptTerms: defineAsyncComponent(() => import('./views/AcceptTerms.vue'))
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    terms: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:terms', 'goToDonorPage', 'goToPaymentPage'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    termsValue: {
      get () {
        return this.terms
      },
      set (value) {
        this.$emit('update:terms', value)
      }
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
.form-switch {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch .form-check-input {
  margin-left: 1em;
  position: absolute;
}

</style>
